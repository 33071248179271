.filterButtonArea {
    display: none;
}

.icon {
    margin-right: 12px;
}

.ref {
    margin-right: 13px;
    padding: 6px;
    border: 1px solid rgb(11, 99, 242);
    border-radius: 6px;
}

.wideScreenOnly {
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.houseWrapper {
    border-radius: 6px;
    height: 100%;
    overflow: scroll;
}

.houseData {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: white;
}

.title {
    padding: 12px;
    color: rgb(11, 99, 242);
    text-transform: uppercase;
    text-align: center;
    transform: scale(0.8);
}

.descrizione {
    padding: 0 20px 20px 20px;
    text-align: justify;
}

.mainData {
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid gray;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.mainData * {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 14px;
}

@media screen and (max-width: 767px) {
    .wideScreenOnly {
        display: none;
    }
    .filterButtonArea {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .title {
        font-size: medium;
        transform: scale(1);
    }
}

@media screen and (max-width: 500px) {
    .mainData {
        flex-flow: column;
        justify-content: center;
    }
}
