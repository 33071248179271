.paginationBar {
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
    color: #555;
    background: linear-gradient(to left, rgb(242, 242, 242), white);
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
}

.arrow {
    margin-left: 20px;
    margin-right: 20px;
    height: 24px;
    width: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
}

.right {
    transform: rotate(180deg);
}
