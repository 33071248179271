.immobili {
    background: linear-gradient(
        to left,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
    );
}

.filterButton {
    width: 100%;
}

.filterFormWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row {
    height: calc(100% - 46px);
}

.housePart {
    flex-flow: column;
}

@media screen and (max-width: 840px) {
    .filterFormWrapper {
        position: relative;
        right: 20px;
    }
}

@media screen and (max-width: 767px) {
    .housePart {
        padding-bottom: 36px;
    }
}
