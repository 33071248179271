.wrapper {
    padding: 30px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column;
    color: white;
    height: 80%;
    border: 1px solid white;
    border-radius: 10px;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/no_houses.jpg");
    z-index: -1;
}

.errorMessage {
    padding: 20px;
    text-align: center;
}

.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonWrapper * {
    margin: 6px;
}

@media screen and (max-width: 500px) {
    .wrapper {
        border: 1px solid transparent;
    }
}
