body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: "Lato", sans-serif;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gray {
    background: linear-gradient(rgb(255, 255, 255), rgb(233, 234, 237));
    z-index: 0;
}

.blue {
    background: linear-gradient(to right, rgb(2, 52, 126), rgb(94, 142, 223));
    z-index: 0;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical {
    flex-flow: column;
}

.fullHeight {
    height: 100%;
}

.fullWidth {
    width: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.grecaptcha-badge {
    z-index: 8000;
    position: relative;
    top: calc(100% - 124px);
}

.page {
    height: calc(100svh - 180px);
    position: relative;
    z-index: 10;
    overflow: scroll;
}

.link {
    text-decoration: none;
    color: rgb(53, 111, 234);
}

.link:hover {
    text-decoration: underline;
}

.rightSpace {
    margin-right: 10px;
    font-size: large;
}

.bordered {
    border: 1px solid white !important;
}

.backdrop {
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 10;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}

.foreGround {
    padding: 40px;
    height: 100%;
    width: 100%;
    color: white;
    z-index: 100;
    border-radius: 10px;
    position: absolute;
    left: 0;
    border-radius: 10px;
}
