.card {
    height: 180px;
    border: 1px solid gray;
    border-radius: 6px;
    flex-flow: column;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    overflow: hidden;
    width: 96%;
}

.card:hover .header img {
    opacity: 1;
    transform: scale(1);
}

.header {
    height: 119px;
    width: 100%;
    border-bottom: 1px solid lightgray;
    overflow: hidden;
}

.header img {
    width: 100%;
    min-height: 100%;
    opacity: 0.8;
    transform: scale(1.1);
    transition: all 0.6s;
}

.main {
    height: 60px;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    font-size: small;
}
