.header {
    width: 100%;
}

.upperHeader {
    background: linear-gradient(to right, #343a3f, #293640);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
    overflow: hidden;
}

.lowerHeader {
    background: linear-gradient(to right, white, white, rgb(236, 248, 237));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4%;
    height: 110px;
    width: 100%;
}

.hamburger {
    display: none;
}

.logo {
    height: 94px;
    width: 196px;
    cursor: pointer;
}

.list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 8px;
}

.nav {
    display: block;
}

.icon {
    height: 17px;
    width: 17px;
    color: white;
    margin-right: 8px;
}

.saga {
    margin-right: 24px;
    color: white;
    text-decoration: none;
    text-align: center;
}

.saga:hover {
    color: rgb(70, 255, 113);
}

.saga:hover .icon {
    color: rgb(70, 255, 113);
}

.email {
    width: 200px;
}

.phone {
    width: 150px;
}

@media screen and (max-width: 550px) {
    .lowerHeader {
        background: linear-gradient(to right, white, white);
    }
    .nav {
        display: none;
    }
    .hamburger {
        display: block;
        height: 60px;
        width: 80px;
        color: lightgrey;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow: column;
        padding: 10px 10px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .hamburger * {
        height: 2px;
        width: 80%;
        background-color: rgb(69, 69, 69);
    }

    .upperHeader {
        padding-right: 0;
    }
}

@media screen and (max-width: 400px) {
    .saga {
        margin-right: 0;
    }
}
