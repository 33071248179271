.wrapper {
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 6px;
    background-color: white;
    height: 450px;
}

.littleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
}

.frame {
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: linear-gradient(
        to bottom,
        rgb(240, 240, 240),
        rgb(236, 236, 236)
    );
}

.imageWrapper {
    justify-content: space-between;
}

.spinnerWrapper {
    justify-content: center;
}

.arrow {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px 15px;
    z-index: 3000;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    cursor: pointer;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    border-right: 1px solid white;
}

.numerazione {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border: 1px solid white;
    border-radius: 6px;
    z-index: 3000;
    padding: 20px;
}

.right {
    transform: rotate(180deg);
}

.imageWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 2000;
}

@media screen and (max-width: 900px) {
    .wrapper {
        height: 400px;
    }
    .littleWrapper {
        height: 400px;
    }
}

@media screen and (max-width: 800px) {
    .wrapper {
        height: 500px;
    }
    .littleWrapper {
        height: 500px;
    }
}

@media screen and (max-width: 700px) {
    .wrapper {
        height: 450px;
    }
    .littleWrapper {
        height: 450px;
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        height: 400px;
    }
    .littleWrapper {
        height: 400px;
    }
}

@media screen and (max-width: 500px) {
    .wrapper {
        height: 350px;
    }
    .littleWrapper {
        height: 350px;
    }
    .wrapper div {
        background-color: rgba(0, 0, 0, 0.4);
        padding: 10px 5px;
        border: none;
    }
}

@media screen and (max-width: 400px) {
    .imageWrapper {
        height: 300px;
    }
    .littleWrapper {
        height: 300px;
    }
}
