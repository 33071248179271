.wrapper {
    height: 100%;
    width: 100%;
    overflow: scroll;
}

.houseList {
    margin-top: 30px;
    margin-bottom: 15px;
    flex-flow: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.wrapper::-webkit-scrollbar {
    width: 4px;
    height: 0;
    border: 1px solid lightgray;
}

.wrapper::-webkit-scrollbar-thumb {
    background: linear-gradient(rgb(0, 0, 0), green);
}

@media screen and (max-width: 850px) {
    .wrapper::-webkit-scrollbar {
        width: 0;
        height: 0;
        border: 1px solid transparent;
    }
}
