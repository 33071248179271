.backdrop {
    width: 100vw;
    height: calc(100vh - 180px);
    padding: 0;
    margin: 0;
    position: absolute;
    top: 140px;
    right: 0;
    border: 1px solid transparent;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.4s;
}

.sidebar {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 210px;
    left: -210px;
    height: calc(100vh - 180px);
    background-color: white;
    border: 1px solid transparent;
    z-index: 200;
    transition: all 0.4s;
}

.visible {
    z-index: 100;
    opacity: 1;
}

.opened {
    left: 0px;
}

.list {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
    padding: 0;
    margin: 0;
}

.list li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: small;
}

.navLink {
    text-decoration: none;
    color: rgb(54, 54, 54);
    font-size: x-large;
    cursor: pointer;
}

.active,
.navLink:hover {
    color: green;
}

.options {
    margin-top: 10px;
    border: 1px solid rgba(218, 218, 218, 1);
    border-radius: 5px;
    padding: 9px;
    box-shadow: 1px 1px 8px rgb(202, 202, 202);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background-color: white;
    font-size: larger;
}

.options div {
    padding: 4px;
    cursor: pointer;
}

.options div:hover {
    color: rgb(15, 100, 55);
}
