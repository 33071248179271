.contatti {
    background: linear-gradient(
        to left,
        rgb(206, 206, 206),
        rgb(255, 255, 255),
        rgb(237, 237, 237)
    );
    color: #333;
}

.dati {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.dati * {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.icon {
    height: 17px;
    width: 17px;
    margin-right: 8px;
}

@media screen and (max-width: 766px) {
    .mapCol {
        padding-top: 30px;
        padding-bottom: 30px;
        min-height: 400px;
    }
}

@media screen and (max-width: 405px) {
    .dati {
        font-size: small;
    }
}
