.wrapper {
    width: 100%;
    height: 100%;
}

.content {
    padding: 20px;
    position: relative;
    width: 80%;
    height: 80%;
    border: 1px solid gray;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.buttons * {
    margin: 6px;
}

.text {
    color: white;
    text-align: center;
    z-index: 90;
    padding-top: 10px;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/no_houses.jpg");
    z-index: -1;
}
