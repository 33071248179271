.immobileNavItem_sidebar {
    margin: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: rgb(54, 54, 54);
    font-size: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    position: relative;
    transition: all 0.3s;
}

.active_sidebar {
    background-color: #085ed6;
    color: white;
}

.immobileNavItem_sidebar:hover {
    background-color: rgb(230, 230, 230);
    color: rgb(54, 54, 54);
}

.immobileNavItem_header {
    margin-right: 20px;
    text-decoration: none;
    color: rgb(54, 54, 54);
    font-size: x-large;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    position: relative;
    top: 3px;
    transition: all 0.3s;
}

.options {
    position: absolute;
    margin-top: 10px;
    border: 1px solid rgba(218, 218, 218, 1);
    border-radius: 5px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background-color: white;
    font-size: larger;
    z-index: 3000;
}

.options_header {
    margin-top: 10px;
}

.options_sidebar {
    margin-top: 10px;
    width: 200px;
    top: 214px;
}

.options div {
    padding: 4px;
    cursor: pointer;
}

.options div:hover {
    color: rgb(15, 100, 55);
}

.immobileNavItem_header:hover {
    border-bottom: 2px solid green;
    color: rgb(54, 54, 54);
}

.active_header {
    border-bottom: 2px solid green;
}

@media screen and (max-width: 720px) {
    .immobileNavItem_header {
        font-size: larger;
    }
    .options {
        font-size: large;
    }
}

@media screen and (max-width: 650px) {
    .immobileNavItem_header {
        font-size: large;
    }
    .caret {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .immobileNavItem_header,
    .options {
        font-size: medium;
    }
}
