.formWrapper {
    border-radius: 6px;
    border: 1px solid gray;
    background: linear-gradient(
        to left,
        rgb(239, 239, 239),
        rgb(230, 230, 230)
    );
    min-height: 420px;
}

.slim {
    min-width: 250px;
    width: 80%;
    height: 420px;
}

.fat {
    width: 100%;
    height: 96%;
}

.form * {
    width: 80%;
}

.form {
    height: 80%;
    width: 80%;
    flex-flow: column;
    justify-content: space-around;
}

@media screen and (min-width: 1050px) {
    .fat {
        width: 80%;
    }
}
