.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
    margin: 0;
    padding: 0;
    font-size: small;
}

.wrapper * {
    width: 90%;
    margin-bottom: 6px;
}

.wrapper select {
    cursor: pointer;
    font-weight: normal;
    border: 1px solid transparent;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid lightgray;
    padding: 2px;
}

@media screen and (max-width: 900px) {
    .wrapper select {
        font-size: small;
    }
}

@media screen and (max-width: 715px) {
    .wrapper select {
        font-size: large;
    }
}

@media screen and (max-width: 550px) {
    .wrapper select {
        font-size: medium;
    }
}

@media screen and (max-width: 400px) {
    .wrapper select {
        font-size: small;
    }
}
