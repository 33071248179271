.formControl {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}

.little * {
    font-size: small !important;
}

.invalid {
    color: red;
    font-size: small;
}

.errorMessage {
    font-size: small;
    color: red;
    position: relative;
    left: -6px;
}

.label {
    position: relative;
    top: 14px;
}
