.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background: linear-gradient(to right, white, rgb(236, 248, 237));
    height: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9900;
}

.footer div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.link {
    text-decoration: none;
    color: rgb(36, 107, 240);
    margin: 0 4px;
}

.link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 525px) {
    .footer {
        font-size: small;
    }
}

@media screen and (max-width: 440px) {
    .footer {
        font-size: x-small;
    }
}
