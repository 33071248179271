.navLink_header {
    margin-right: 20px;
    text-decoration: none;
    color: rgb(54, 54, 54);
    font-size: x-large;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s;
}

.navLink_sidebar {
    margin: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: rgb(54, 54, 54);
    font-size: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    transition: all 0.3s;
}

.active_header,
.navLink_header:hover {
    border-bottom: 2px solid green;
    color: rgb(54, 54, 54);
}

.active_sidebar {
    background-color: #1e69d3;
    color: white;
}

.navLink_sidebar:hover {
    background-color: rgb(230, 230, 230);
    color: rgb(54, 54, 54);
}

@media screen and (max-width: 720px) {
    .navLink_header {
        font-size: larger;
    }
}

@media screen and (max-width: 650px) {
    .navLink_header {
        font-size: large;
    }
    .caret {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .navLink_header {
        font-size: medium;
    }
}
