.absolute {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.imageTv {
    z-index: 8002;
}

.text {
    z-index: 8004;
    text-transform: uppercase;
    font-size: large;
    font-weight: bold;
    padding: 0 20%;
    color: white;
    text-align: center;
    box-sizing: border-box;
    font-size: x-large;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column;
}

.darkBackdrop {
    z-index: 8003;
    background-color: rgba(0, 0, 0, 0.5);
}

.title {
    transform: scale(1.8);
    width: 65%;
}

.body {
    width: 86%;
}
