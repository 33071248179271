.button {
    height: 26px;
    padding: 20px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.7s;
    border: 1px solid gray;
}

.fillSpace {
    width: 100% !important;
    height: 100% !important;
}

.disabled {
    border: 1px solid gray !important;
    background-color: rgb(124, 170, 226) !important;
    color: gray !important;
    cursor: not-allowed !important;
}

.green {
    background-color: rgb(177, 241, 177);
    color: rgb(54, 54, 54);
}

.green:hover {
    background-color: rgb(79, 230, 79);
}

.green_outline {
    background-color: rgb(255, 255, 255);
    color: green;
    border: 1px solid green;
}

.green_outline:hover {
    background-color: rgb(177, 241, 177);
    color: rgb(54, 54, 54);
}

.blue {
    background-color: rgb(79, 147, 230);
    color: rgb(236, 236, 236);
}

.blue:hover {
    background-color: rgb(5, 110, 237);
}

.blue_outline {
    background-color: rgb(224, 223, 223);
    color: rgb(5, 110, 237);
    border: 1px solid rgb(5, 110, 237);
}

.blue_outline:hover {
    background-color: rgb(255, 255, 255);
}

.red {
    background-color: rgb(241, 185, 177);
    color: rgb(54, 54, 54);
}

.red:hover {
    background-color: rgb(173, 7, 7);
    color: white;
}

.red_outline {
    background-color: rgb(232, 232, 232);
    color: red;
    border: 1px solid red;
}

.red_outline:hover {
    background-color: white;
}
