.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 500;
}

.local {
    width: 100%;
    height: 100%;
}

.universal {
    width: 100vw;
    height: 100vh;
}

.modal {
    width: 50%;
    min-width: 320px;
    background-color: white;
    z-index: 600;
    border-radius: 6px;
    flex-flow: column;
}

.modal * {
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.header {
    height: 30%;
    font-weight: bold;
}

.main {
    height: 50%;
    flex-flow: column;
}

.main * {
    margin: 0;
    padding: 0;
}

.footer {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer * {
    margin: 0 5px;
}
