.banner {
    height: 120px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: linear-gradient(
        to left,
        rgb(28, 28, 28),
        rgb(103, 103, 103),
        rgb(43, 42, 42)
    );
    justify-content: space-around !important;
    padding: 20px;
    font-weight: lighter;
    font-size: small;
    color: white;
    border-top: 1px solid white;
}

.text {
    max-width: 70%;
    text-align: justify;
}

.x {
    font-size: xx-large;
    font-weight: normal;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 6px;
    color: rgb(198, 198, 198);
    transition: all 0.6s;
}

.x:hover {
    transform: scale(1.1);
    color: white;
}

@media screen and (max-width: 1050px) {
    .banner {
        height: 140px;
    }
}

@media screen and (max-width: 800px) {
    .banner {
        height: 160px;
    }
    .x {
        position: relative;
        left: -8px;
    }
}

@media screen and (max-width: 630px) {
    .banner {
        height: 180px;
    }
}

@media screen and (max-width: 580px) {
    .banner {
        height: 200px;
        font-size: smaller;
    }
}

@media screen and (max-width: 480px) {
    .banner {
        height: 240px;
    }
}

@media screen and (max-width: 400px) {
    .banner {
        height: 280px;
    }
}

@media screen and (max-width: 380px) {
    .banner {
        height: 320px;
    }
}

@media screen and (max-width: 400px) {
    .x {
        position: relative;
        left: 8px;
    }
}
