.houseCard {
    border: 1px solid gray;
    width: 90%;
    height: 300px;
    background-color: rgb(242, 242, 242);
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.houseCard:hover {
    color: black;
    background-color: white;
}

.houseCard:hover img {
    transform: scale(1);
    opacity: 1;
}

.spinnerWrapper {
    width: 300px;
}

.imgWrapper {
    height: 100%;
    max-width: 300px;
    border-radius: 6px;
    overflow: hidden;
    background: linear-gradient(rgb(255, 255, 255), rgb(215, 215, 215));
}

.imgWrapper img {
    opacity: 0.9;
    transform: scale(1.1);
    min-height: 100%;
    min-width: 100%;
    transition: 0.6s all;
}

.textPart {
    width: 60%;
    height: 100%;
    padding: 20px;
    text-align: start;
    box-sizing: border-box;
    flex-flow: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    color: rgb(56, 56, 56);
    transition: all 0.6s;
}

.textPart * {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.textPart:hover {
    color: black;
}

.titolo {
    text-align: start;
    font-weight: bold;
    color: rgb(5, 89, 215);
}

.ref {
    position: relative;
    top: 2px;
    padding: 2px 6px 2px 6px;
    border: 1px solid rgb(5, 89, 215);
    border-radius: 10px;
    margin-right: 6px;
}

.ref:hover {
    text-decoration: none !important;
}

.icon {
    margin-right: 6px;
    transform: scale(0.8);
    position: relative;
    left: -4px;
}

.euroicon {
    margin-right: 6px;
    transform: scale(0.8);
}

.sup {
    vertical-align: super;
    font-size: xx-small;
}

@media screen and (max-width: 1050px) {
    .imgWrapper {
        max-width: 250px;
    }
    .spinnerWrapper {
        width: 250px;
    }
}

@media screen and (max-width: 940px) {
    .textPart {
        font-size: small;
    }
}

@media screen and (max-width: 850px) {
    .imgWrapper {
        height: 90%;
        max-width: 200px;
    }
    .spinnerWrapper {
        width: 200px;
    }
    .wrapper {
        padding: 20px;
    }
}

@media screen and (max-width: 767px) {
    .textPart {
        font-size: medium;
    }
    .imgWrapper {
        height: 100%;
        max-width: 300px;
    }
    .spinnerWrapper {
        width: 300px;
    }
    .wrapper {
        padding: 0;
    }
}

@media screen and (max-width: 700px) {
    .imgWrapper {
        max-width: 250px;
    }
    .spinnerWrapper {
        width: 250px;
    }
    .houseCard {
        height: 250px;
    }
}

@media screen and (max-width: 600px) {
    .textPart {
        font-size: small;
    }
}

@media screen and (max-width: 550px) {
    .houseCard {
        height: 300px;
    }
    .titolo {
        padding: 20px 20px 0px 20px;
        position: relative;
        top: 10px;
        text-align: center;
        line-height: 180%;
    }

    .imgWrapper {
        height: 200px;
        max-width: 200px;
    }
    .spinnerWrapper {
        width: 200px;
    }
    .wrapper {
        height: 250px;
        padding: 0 25px 25px 25px;
    }
}

@media screen and (max-width: 450px) {
    .titolo {
        font-size: small;
    }
    .houseCard {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: column;
        height: 90%;
    }
    .imgWrapper {
        max-width: 250px;
        height: 250px;
        position: relative;
        top: 10px;
    }
    .textPart {
        width: 80%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }
    .spinnerWrapper {
        width: 250px;
        height: 250px;
    }
    .houseCard {
        height: 490px;
    }
}
