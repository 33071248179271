.container {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: linear-gradient(darkgray, black);
    z-index: 8001;
}

.wrapper {
    z-index: 9999;
}
