.backdropImage {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #cccccc;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    opacity: 0;
}

.image_0 {
    background-image: url("../../assets/living.jpeg");
    animation-name: backdrop;
}

.image_1 {
    background-image: url("../../assets/ufficio.jpg");
    animation-name: backdrop;
    animation-delay: 12s;
}

.image_2 {
    background-image: url("../../assets/kitchen.jpeg");
    animation-name: backdrop;
    animation-delay: 24s;
}

.image_3 {
    background-image: url("../../assets/capannone.jpg");
    animation-name: backdrop;
    animation-delay: 36s;
}

.image_4 {
    background-image: url("../../assets/agenzia.png");
    animation-name: backdrop;
    animation-delay: 48s;
}

@keyframes backdrop {
    0% {
        opacity: 0;
        transform: translate(-20px, 0);
    }
    20% {
        opacity: 1;
        transform: translate(0, 0);
    }
    40% {
        opacity: 0;
        transform: translate(20px, 0);
    }
}
