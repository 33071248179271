.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
}

.buttons * {
    margin: 4px 10px 0 4px;
}

.frame {
    height: 400px;
    width: 90%;
    position: relative;
    border-radius: 8px;
    text-align: center;
}

@media screen and (max-width: 992px) {
    .frame {
        height: 450px;
    }
}

@media screen and (max-width: 500px) {
    .frame {
        height: 100%;
        max-height: 500px;
        width: 100vw;
    }
}

@media screen and (max-width: 400px) {
    .frame {
        font-size: small;
    }
}
